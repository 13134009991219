import React from 'react'
import { Switch, Route, } from "react-router-dom"
import AppViews from 'views/app'
import Header from 'layouts/utility/common/Header'
import Footer from 'layouts/utility/common/Footer';
import MemberHeader from './utility/common/MemberHeader';
import Cookies from 'js-cookie';
let role = Cookies.get("sm_role");

export const AppLayout = () => {
    return (
        <div className="sm-app">
            {role === "agent" ? <Header /> : <MemberHeader />}
            <Switch >
                <Route path="" component={AppViews} />
            </Switch>
            <Footer />
        </div>
    )
}

export default AppLayout;