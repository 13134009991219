import React from "react"
import { Switch, Route, useLocation, } from "react-router-dom"
import WebHeader from "./utility/web/WebHeader"
import WebFooter from "./utility/web/WebFooter"
import WebViews from "views/web"

export const WebLayout = () => {
    const location = useLocation();

    return (
        <div className="sm-web">
            {!location.pathname.includes("mob") && <WebHeader />}
            <Switch>
                <Route path="" component={WebViews} />
            </Switch>
            {!location.pathname.includes("mob") && <WebFooter />}
        </div>
    )
}

export default WebLayout;