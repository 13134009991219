import React from 'react';
import { Row, Button, Col } from 'antd';
import { Link } from 'react-router-dom';

const JoinMe = () => {
	return (
		<>
			<Row className="join-card">
				<Col md={24} xs={0}>
					<div className="join-free txt-center">
						<h1>Join our growing site, and find your     <span>soul mate</span></h1>
						<h4>South Matrimony has helped to make many thousands of people happy, so go ahead and...</h4>
						<Link to="/auth/member-registration">
							<Button className="sm-btn sm-btn-danger">Register For Free</Button>
						</Link>
						<Link to="/auth/agent-registration">
							<Button className="sm-btn sm-btn-black">Register For Agent</Button>
						</Link>
					</div>
				</Col>
			</Row>
			{/* mobile Responsive */}
			<Row>
				<Col xs={1} md={0} />
				<Col xs={22} md={0} className='join-card-mob'>
					<div className="txt-center">
						<h3>Join our growing site, and find your     <span>soul mate</span></h3>
						<h5>South Matrimony has helped to make many thousands of people happy, so go ahead and...</h5>
						<br />
						<Row gutter={[16, 0]}>
							<Col xs={12}>
								<Link to="">
									<Button className="sm-btn sm-btn-danger">Register For Free</Button>
								</Link>
							</Col>
							<Col xs={12}>
								<Link to="/auth/agent-registration">
									<Button className="sm-btn sm-btn-black">Register For Agent</Button>
								</Link>
							</Col>
						</Row>
						<br />
					</div>
				</Col>
				<Col xs={1} md={0} />
			</Row>
		</>
	)
}

export default JoinMe;
