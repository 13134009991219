import { useQuery } from "react-query";
import * as api from "services/AgentServices";

export const useGetNotifications = () => {
    return useQuery("GetNotifications", () => api.getAllNotifications(), {
        enabled: false,
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
    });
}
export const useGetAsRead = (id) => {
    return useQuery(["MarkAsRead", id], () => api.getMarkAsRead(id), {
        enabled: false,
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
    });
}

export const useGetAllAsRead = (id) => {
    return useQuery(["MarkAllAsRead", id], () => api.getMarkAllAsRead(id), {
        enabled: false,
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
    });
}

export const useAcceptorReject = (id, id1) => {
    return useQuery(["AcceptorReject", id, id1], () => api.acceptOrRejectRequest(id, id1), {
        enabled: false,
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
    });
}


