import { useMutation, useQuery, useQueryClient } from "react-query";
import * as api from "services/MemberProfileServices";

export const useMemeberEditBasicInfo = () => {
    const queryClient = useQueryClient();
    return useMutation((formData) => api.memberEditBasicInfo(formData), {
        onSuccess: () => {
            queryClient.refetchQueries('MemberProfileView');
            queryClient.refetchQueries('UserProfile');
            queryClient.refetchQueries('ProfileStatus');
        }
    });
}
export const useMemeberEditProfileImage = () => {
    return useMutation((formData) => api.memberEditProfileImage(formData));
}

export const useMemberProfiles = () => {
    return useMutation((formData) => api.memberProfiles(formData));
}

export const useMemberRecentlyViewd = (id) => {
    return useMutation(() => api.memberRecentlyViewed(id));
}

export const useMemberEditDefaultImage = () => {
    const queryClient = useQueryClient();
    return useMutation((formData) => api.memberEditDefaultImage(formData), {
        onSuccess: () => {
            queryClient.refetchQueries('MemberOwnProfile');
        }
    });
}

export const useMemberBasicinfo = () => {
    return useQuery("BasicInfo", () => api.getMemberBasicInfo(), {
        enabled: false,
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
    });
}

export const useMemberOwnProfile = () => {
    return useQuery("MemberOwnProfile", () => api.getMemberOwnProfile(), {
        enabled: false,
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
    });
}

export const useMemberEditFamilyDetails = () => {
    const queryClient = useQueryClient();
    return useMutation((formData) => api.memberEditFamilyDetails(formData), {
        onSuccess: () => {
            queryClient.refetchQueries('ProfileStatus');
            queryClient.refetchQueries('FamilyDetails');
            queryClient.refetchQueries('MemberProfileView');
        }
    });
}

export const useMemberFamilyDetails = () => {
    return useQuery("FamilyDetails", () => api.getMemberFamilyDetails(), {
        enabled: false,
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
    });
}

export const useMemberEditLifeStyleDetails = () => {
    const queryClient = useQueryClient();
    return useMutation((formData) => api.memberEditLifeStyleDetails(formData), {
        onSuccess: () => {
            queryClient.refetchQueries('ProfileStatus');
            queryClient.refetchQueries('LifeStyleDetails');
            queryClient.refetchQueries('MemberProfileView');
        }
    });
}

export const useMemberLifeStyleDetails = () => {
    return useQuery("LifeStyleDetails", () => api.getMemberLifeStyleDetails(), {
        enabled: false,
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
    });
}

export const useMemberEditAstroDetails = () => {
    const queryClient = useQueryClient();
    return useMutation((formData) => api.memberEditAstroDetails(formData), {
        onSuccess: () => {
            queryClient.refetchQueries('ProfileStatus');
            queryClient.refetchQueries('AstroDetails');
            queryClient.refetchQueries('MemberProfileView');
        }
    });
}

export const useMemberAstroDetails = () => {
    return useQuery("AstroDetails", () => api.getMemberAstroDetails(), {
        enabled: false,
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
    });
}

export const useMemberEditReligiousDetails = () => {
    const queryClient = useQueryClient();
    return useMutation((formData) => api.memberEditReligiousDetails(formData), {
        onSuccess: () => {
            queryClient.refetchQueries('ProfileStatus');
            queryClient.refetchQueries('ReligiousDetails');
            queryClient.refetchQueries('MemberProfileView');
        }
    });
}

export const useMemberReligiousDetails = () => {
    return useQuery("ReligiousDetails", () => api.getMemberReligiousDetails(), {
        enabled: false,
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
    });
}

export const useMemberEditEducationDetails = () => {
    const queryClient = useQueryClient();
    return useMutation((formData) => api.memberEditEducationDetails(formData), {
        onSuccess: () => {
            queryClient.refetchQueries('ProfileStatus');
            queryClient.refetchQueries('EducationDetails');
            queryClient.refetchQueries('MemberProfileView');
        }
    });
}

export const useMemberEducationDetails = () => {
    return useQuery("EducationDetails", () => api.getMemberEducationDetails(), {
        enabled: false,
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
    });
}

export const useMemberEditLocationDetails = () => {
    const queryClient = useQueryClient();
    return useMutation((formData) => api.memberEditLocationDetails(formData), {
        onSuccess: () => {
            queryClient.refetchQueries('ProfileStatus');
            queryClient.refetchQueries('MemberLocationDetails');
            queryClient.refetchQueries('MemberProfileView');
        }
    });
}

export const useMemberLocationDetails = () => {
    return useQuery("MemberLocationDetails", () => api.getMemberLocationDetails(), {
        enabled: false,
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
    });
}

export const useMemberEditSocialProfiles = () => {
    const queryClient = useQueryClient();
    return useMutation((formData) => api.memberEditSocialProfiles(formData), {
        onSuccess: () => {
            queryClient.refetchQueries('ProfileStatus');
            queryClient.refetchQueries('MemberSocialProfiles');
            queryClient.refetchQueries('MemberProfileView');
        }
    });
}

export const useMemberSocialProfiles = () => {
    return useQuery("MemberSocialProfiles", () => api.getMemberSocialProfiles(), {
        enabled: false,
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
    });
}

export const useMemberEditPartnerPreference = () => {
    const queryClient = useQueryClient();
    return useMutation((formData) => api.memberEditPartnerPreferenceDetails(formData), {
        onSuccess: () => {
            queryClient.refetchQueries('MemberPartnerInfo');
            queryClient.refetchQueries('MemberProfileView');
        }
    });
}

export const useMemberPartnerInfo = () => {
    return useQuery("MemberPartnerInfo", () => api.getMemberPartnerDetails(), {
        enabled: false,
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
    });
}

export const useMemberChangeEmail = () => {
    return useMutation((formData) => api.memberChangeEmail(formData));
}

export const useMemberDeactivateAccount = () => {
    return useMutation((formData) => api.memberDeactivateAccount(formData));
}

export const useMemberProfileView = (id) => {
    return useQuery(["MemberProfileView", id], () => api.memberProfileView(id), {
        enabled: false,
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
    });
}
