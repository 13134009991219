import React, { useEffect } from 'react';
import { Row, Col, Menu, Layout } from 'antd';
import { Dropdown, Space } from 'antd';
import PrivateImage from 'helpers/privateImage';
import { Link } from 'react-router-dom';
import Image from 'helpers/Image'
import Cookies from "js-cookie";
import { useMemberOwnProfile } from 'hooks/member/Profiles';
import NotifyBody from 'views/shared-components/popover/NotifyBody';
import { camelize, clientId } from 'helpers/commonFunctions';
import MemberMobileNav from './MemberMobileNav';
import { useGoogleLogout } from 'react-google-login';

const { Header } = Layout;

const MemberHeader = () => {
    // let history = useHistory();
    const { data: userDetails, refetch } = useMemberOwnProfile();
    const userData = userDetails?.data;
    let profileImage = userData?.profileImage;


    useEffect(() => {
        refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    const menu = (
        <Menu
            items={[
                {
                    label: (
                        <Link to="/member/account/profile-details">
                            <h4><div className='profile-tops'><Image name={"user.svg"} className={"icon-img"} />My Profile</div></h4>
                        </Link>
                    ),
                    key: '0',
                },
                {
                    label: (
                        <h4 onClick={(e) => onLogout(e)} ><div className='profile-to'><Image name={"out.svg"} className={"icon-ig"} />Log Out</div></h4>
                    ),
                    key: '1',
                },
            ]}
        />
    );


    const removeStorage = () => {
        Cookies.remove('sm_register_token');
        Cookies.remove('sm_register_domain');
        Cookies.remove('sm_register_phone');
        Cookies.remove('sm_register_otp');
        Cookies.remove('sm_role');
        Cookies.remove('profile_user_id');
        Cookies.remove("member-data");
        Cookies.remove('google-mail');
		Cookies.remove('google-data');
        Cookies.remove("user-view");
        Cookies.remove('google-data');
        sessionStorage.clear();
        window.location.reload("/web/home");
    }

    const { signOut } = useGoogleLogout({
        clientId: clientId,
    })

    const onLogout = (e) => {
        e.preventDefault()
        signOut();
        removeStorage();
    }

    return (
        <header className="sm-app-header app-page mob-head" id="top-head">
            <Row>
                <Col md={5} xs={22}>
                    <Link to="/member/dashboard">
                        <Image name={"logo.png"} className="logo logo-img" />
                    </Link>
                </Col>
                <Col md={14} xs={0}>
                    <Header>
                        <Menu
                            theme="dark"
                            mode="horizontal"
                            //defaultSelectedKeys={['0']}
                            className="sm-main-menu"
                        //items={MenuItems}
                        >
                            <Menu.Item key="0">
                                <Link to="/member/dashboard">
                                    <span>Home</span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="111">
                                <Link to="/member/profiles">
                                    <span>Profiles</span>
                                </Link>
                            </Menu.Item>
                            <Menu.SubMenu key="22" title="Matches">
                                <Menu.Item key="221">
                                    <Link to="/member/matches/preferred-matches">
                                        <span>Preferred Matches</span>
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key="222">
                                    <Link to="/member/matches/recently-viewed-profiles">
                                        <span>Recently Viewed Profiles</span>
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key="228">
                                    <Link to="/member/matches/my-shortlisted-profiles">
                                        <span>My Shortlisted Profiles</span>
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key="231">
                                    <Link to="/member/matches/star-lagna-match-profiles">
                                        <span>Star & Lagna Match Profiles</span>
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key="230">
                                    <Link to="/member/matches/who-viewed-my-profile">
                                        <span>Who Viewed My Profile</span>
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key="223">
                                    <Link to="/member/matches/who-downloaded-my-profile">
                                        <span>Who Downloaded My Profiles</span>
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key="224">
                                    <Link to="/member/matches/who-shortlisted-my-profile">
                                        <span>Who Shortlisted My Profiles</span>
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key="232">
                                    <Link to="/member/matches/my-downloaded-profiles">
                                        <span>My Downloaded Profiles</span>
                                    </Link>
                                </Menu.Item>
                            </Menu.SubMenu>
                            <Menu.SubMenu key="33" title="Search">
                                <Menu.Item key="331">
                                    <Link to="/member/search/profile-search">
                                        <span>Profile Search</span>
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key="333">
                                    <Link to="/member/search/chevvai-dosham-profiles">
                                        <span>Chevvai Dosham Profiles</span>
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key="334">
                                    <Link to="/member/search/physically-challenged-profiles">
                                        <span>Physically Challanged Profiles</span>
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key="335">
                                    <Link to="/member/search/widower-divorcee-profiles">
                                        <span>Widower / Divorcee Profiles</span>
                                    </Link>
                                </Menu.Item>
                            </Menu.SubMenu>
                            <Menu.Item key="55">
                                <Link to="/member/contact/contact-us">
                                    <span>Contact Us</span>
                                </Link>
                            </Menu.Item>
                        </Menu>
                    </Header>
                </Col>
                <Col md={0} xs={2} className="txt-right">
                    <MemberMobileNav count={userData?.cartCount} />
                </Col>
                <Col md={5} xs={0} style={{ marginTop: "-5px" }}>
                    <Link to="/member/cart">
                        {userData?.cartCount > 0 && <p className='cart-count'>{userData?.cartCount}</p>}
                        <Image name={"bags.svg"} className={"bags"} />&nbsp;&nbsp;
                    </Link>
                    <NotifyBody />
                    <Dropdown overlay={menu} className="drop-data">
                        <Space onClick={(e) => e.preventDefault()}>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <div className='profile-top'>
                                {profileImage ? <PrivateImage privateUrl={profileImage} str="full-size-header" val={userData?.name} className={userData?.name ? "profile-top-img space-margin" : "profile-top-img add-spacee"} />
                                    :
                                    <Image name={userData?.gender === "Male" ? "avatar-man.png" : "avatar-woman.png"} className={userData?.name ? "profile-top-dummy" : "profile-top-dummy add-space"} />
                                }
                                <span>{userData?.name && camelize(userData?.name)}</span>
                            </div>
                            <Image name={"poly.svg"} className={"poly-arrow"} />
                        </Space>
                    </Dropdown>
                </Col>
            </Row>
        </header>
    )
}

export default MemberHeader;