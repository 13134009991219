import axios from "axios";
import { message } from "antd";
import Cookies from "js-cookie";


// const REACT_APP_BASE_API = process.env.REACT_APP_BASE_API;
// const REACT_APP_BASE_API = process.env.REACT_APP_BASE_API;
const SECONDS = 30;
const MILISECONDS = 1000;
const TIMEOUT = SECONDS * MILISECONDS;
const TOKEN = sessionStorage.getItem("sm_register_token") || Cookies.get("member-token");
let role = Cookies.get("sm_role");


const client = axios.create({
    baseURL: "https://api.southmatrimony.com/",
    timeout: TIMEOUT,
    headers: {
        "content-type": "application/json",
        "Authorization": `Bearer ${TOKEN}`,
    }
});
client.interceptors.request.use(function (config) {
    return config;
});
client.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error?.response?.status === 401) {
            // window.location = "/";
        } else if (error.response.status !== 200) {
            message.error(error.response.data.data);
        } else {
            console.error(error);
        }
        return Promise.reject(error);
    }
);

const dashboardCount = async () => {
    const { data } = await client.get(`agent/dashboard`);
    return data;
}
const uploadedProfiles = async (formData) => {
    const { data } = await client.post(`agent/uploadedProfiles/v2`, formData);
    return data;
}
const paidProfiles = async (formData) => {
    const { data } = await client.post(`agent/paidProfiles/v2`, formData);
    return data;
}
const matchingProfiles = async (formData) => {
    const { data } = await client.post(`agent/matches/preferredMatches/v2`, formData);
    return data;
}
const shortListedProfiles = async (formData) => {
    const { data } = await client.post(`agent/matches/myShortlistedProfiles/v2`, formData);
    return data;
}

const recentlyViewedProfiles = async (formData) => {
    const { data } = await client.post(`agent/recentlyViewedProfiles/v2`, formData);
    return data;
}
const chevvaiDoshamProfiles = async (formData) => {
    const { data } = await client.post(`agent/chevvaiDoshamProfiles/v2`, formData);
    return data;
}
const physicallyChallengedProfiles = async (formData) => {
    const { data } = await client.post(`agent/physicallyChallengedProfiles/v2`, formData);
    return data;
}
const widowerProfiles = async (formData) => {
    const { data } = await client.post(`agent/widowerOrDivorceeProfiles/v2`, formData);
    return data;
}
const reportUploadedProfiles = async (formData) => {
    const { data } = await client.post(`agent/reports/uploadedProfiles/v2`, formData);
    return data;
}
const reportUploadedProfilesCsv = async (formData) => {
    const { data } = await client.post(`agent/reports/uploadedProfiles/csv/v2`, formData);
    return data;
}
const reportPaidProfiles = async (formData) => {
    const { data } = await client.post(`agent/reports/paidProfiles/v2`, formData);
    return data;
}
const reportPaidProfilesCsv = async (formData) => {
    const { data } = await client.post(`agent/reports/paidProfiles/csv/v2`, formData);
    return data;
}
const profileView = async (formData) => {
    const { data } = await client.post(`agent/matches/shortlistProfiles`, formData);
    return data;
}
const allProfileView = async (id) => {
    const { data } = await client.get(`agent/userProfile/viewCompleteProfile/${id}`);
    return data;
}
const allProfileStatus = async (id) => {
    const { data } = await client.get(`/userProfile/profileCompletionStatus?userProfileId=${id}`);
    return data;
}
const dissmissStatus = async (id) => {
    const { data } = await client.get(`changeDismissStatus?uniqueCode=${id}`);
    return data;
}
const getStatus = async (id) => {
    const { data } = await client.get(`getDismissStatus?uniqueCode=${id}`);
    return data;
}
const singleMatcheProfile = async (id) => {
    const { data } = await client.get(`agent/matchingProfile/getSingleProfile/${id}?status=1`);
    return data;
}
const preferedMatches = async (id, page) => {
    const { data } = await client.get(`matches/preferredMatches?uniqueCode=${id}&page=${page}`);
    return data;
}
const starMatches = async (id, page) => {
    const { data } = await client.get(`agent/starMatches?uniqueCode=${id}&page=${page}`);
    return data;
}
const lagnaMatches = async (id, page) => {
    const { data } = await client.get(`agent/lagnaMatches?uniqueCode=${id}&page=${page}`);
    return data;
}
const profileViewed = async (id, page) => {
    const { data } = await client.get(`agent/matches/whoViewedMyProfile/v2?uniqueCode=${id}&page=${page}`);
    return data;
}
const viewedMember = async (id, page) => {
    const { data } = await client.get(`agent/matches/whoViewedMyProfile/list?uniqueCode=${id}&page=${page}`);
    return data;
}
const downloadMember = async (id, page) => {
    const { data } = await client.get(`agent/whoDownloadedMyProfiles/list?uniqueCode=${id}&page=${page}`);
    return data;
}
const shortListedMember = async (id, page) => {
    const { data } = await client.get(`agent/matches/whoShortlistedMyProfiles/list?uniqueCode=${id}&page=${page}`);
    return data;
}
const getUserProfile = async (id) => {
    const { data } = await client.get(`myProfile/getAboutUser?uniqueCode=${id}`);
    return data;
}
const getFamilyDetails = async (id) => {
    const { data } = await client.get(`myProfile/getFamilyDetails?uniqueCode=${id}`);
    return data;
}
const getLifeStyleDetails = async (id) => {
    const { data } = await client.get(`myProfile/getLifeStyleDetails?uniqueCode=${id}`);
    return data;
}
const getLocationDetails = async (id) => {
    const { data } = await client.get(`myProfile/getLocationDetails?uniqueCode=${id}`);
    return data;
}
const getSocialDetails = async (id) => {
    const { data } = await client.get(`myProfile/getSocialProfiles?uniqueCode=${id}`);
    return data;
}
const getAstroDetails = async (id) => {
    const { data } = await client.get(`myProfile/getAstroDetails?uniqueCode=${id}`);
    return data;
}
const getReligiousDetails = async (id) => {
    const { data } = await client.get(`myProfile/getReligiousDetails?uniqueCode=${id}`);
    return data;
}
const getEducationDetails = async (id) => {
    const { data } = await client.get(`myProfile/getEducationDetails?uniqueCode=${id}`);
    return data;
}
const getPartnerDetails = async (id) => {
    const { data } = await client.get(`myProfile/getPartnerPreferenceDetails?uniqueCode=${id}`);
    return data;
}
const addNewProfile = async (formData) => {
    const { data } = await client.post(`agent/createUserProfileByAgent`, formData);
    return data;
}
const editPrimaryProfile = async (formData, id) => {
    const { data } = await client.post(`myProfile/editUserProfileByAgent?uniqueCode=${id}`, formData);
    return data;
}
const addFamilyDetails = async (formData) => {
    var fData = JSON.parse(formData);
    const { data } = await client.post(`myProfile/editFamilyDetail?uniqueCode=${fData.uniqueCode}`, formData);
    return data;
}
const addLifeStyleDetails = async (formData) => {
    var fData = JSON.parse(formData);
    const { data } = await client.post(`myProfile/editLifeStyleDetail?uniqueCode=${fData.uniqueCode}`, formData);
    return data;
}

const addReligiousDetails = async (formData) => {
    var fData = JSON.parse(formData);
    const { data } = await client.post(`myProfile/editReligionDetails?uniqueCode=${fData.uniqueCode}`, formData);
    return data;
}
const addCareerDetails = async (formData) => {
    var fData = JSON.parse(formData);
    const { data } = await client.post(`myProfile/editCareerDetails?uniqueCode=${fData.uniqueCode}`, formData);
    return data;
}
const addLocationDetails = async (formData) => {
    var fData = JSON.parse(formData);
    const { data } = await client.post(`myProfile/editLocationDetailsByAgent?uniqueCode=${fData.uniqueCode}`, formData);
    return data;
}
const addSocialMediaDetails = async (formData, id) => {
    const { data } = await client.post(`agent/myProfile/createOrEditSocialProfiles?uniqueCode=${id}`, formData);
    return data;
}
const addPartnerPreferenceDetails = async (formData) => {
    var fData = JSON.parse(formData);
    const { data } = await client.post(`myProfile/editPartnerPreferenceDetails?uniqueCode=${fData.uniqueCode}`, formData);
    return data;
}

const addAstroDetails = async (formData, id) => {
    const { data } = await client.post(`myProfile/editAstroDetails?uniqueCode=${id}`, formData);
    return data;
}
const agentMyProfiles = async () => {
    const { data } = await client.get(`agent/myProfile`);
    return data;
}
const agentSubscriptions = async () => {
    const { data } = await client.get(`agent/subscriptionDetails`);
    return data;
}
const getAgentProfile = async () => {
    const { data } = await client.get(`agent/myProfile`);
    return data;
}
const editAgentProfile = async (formData) => {
    const { data } = await client.post(`agent/editAgentDetails`, formData);
    return data;
}
const changeContact = async (formData) => {
    const { data } = await client.post(`accountDetails/changeContact`, formData);
    return data;
}
const agentVerifyOtp = async (formData) => {
    const { data } = await client.post(`accountDetails/verifyOtp`, formData);
    return data;
}
const agentResendOtp = async (formData) => {
    const { data } = await client.post(`accountDetails/resendOtp`, formData);
    return data;
}
const profileActive = async (formData) => {
    const { data } = await client.post(`agent/activateUserProfile`, formData);
    return data;
}
const profileEnterView = async (formData) => {
    const { data } = await client.post(`agent/userProfile/addViewProfileLog`, formData);
    return data;
}

const profileDeactive = async (formData) => {
    const { data } = await client.post(`agent/deactivateUserProfile`, formData);
    return data;
}
const profileSearch = async (formData) => {
    const { data } = await client.post(`agent/quickSearchProfiles/v2`, formData);
    return data;
}
const changeAgentPassword = async (formData) => {
    const { data } = await client.post(`public/changePassword`, formData);
    return data;
}
const downloadProfileApi = async (id) => {
    const { data } = await client.get(`viewProfileInPdf/${id}`);
    return data;
}

const fetchAwsImage = async (imgPath) => {
    const { data } = await client.get(`getFileStream?path=${imgPath}`);
    return data;
}

const shortlistEnterView = async (id1, id2) => {
    const { data } = await client.post(`agent/matches/shortlistProfiles/${id1}/${id2}`);
    return data;
}

const addCart = async (id) => {
    let newRole = role === "agent" ? 2 : 1;
    const { data } = await client.get(`cart/addToCart/${id}?role=${newRole}`);
    return data;
}

const selectedItemsForCart = async (id1, id2) => {
    const { data } = await client.get(`payment/getSelectedItemsFromCart?cartIds=${id1}&pricingFor=${id2}`);
    return data;
}

const callBackPayment = async (formData) => {
    const { data } = await client.post(`callback`, formData);
    return data;
}

const getCart = async () => {
    let url = role === "member" ? "?role=1" : ""
    const { data } = await client.get(`cart/getAllProfiles${url}`);
    return data;
}
const deleteCart = async (id) => {
    const { data } = await client.get(`cart/removeFromCart/${id}`);
    return data;
}
const clearCart = async (id) => {
    const { data } = await client.get(`cart/removeAllFromCart?cartIdList=${id}`);
    return data;
}

const editProfilePicture = async (formData) => {
    const { data } = await client.post(`agent/editProfileImage`, formData);
    return data;
}

const editLogo = async (formData) => {
    const { data } = await client.post(`agent/editLogoImage`, formData);
    return data;
}

const deleteProfileImage = async (id) => {
    const { data } = await client.delete(`deleteProfileImage/${id}`);
    return data;
}

const getAllNotifications = async () => {
    const { data } = await client.get(`getAllUnreadNotifications`);
    return data;
}

const getUploadedImages = async (id) => {
    const { data } = await client.get(`getUploadedImages?uniqueCode=${id}`);
    return data;
}

const getMarkAsRead = async (id) => {
    const { data } = await client.get(`markAsRead?interestId=${id}`);
    return data;
}

const getMarkAllAsRead = async (id) => {
    const { data } = await client.get(`markAllAsRead?interestIds=${id}`);
    return data;
}

const acceptOrRejectRequest = async (id, id1) => {
    const { data } = await client.get(`interest/acceptOrReject?interestId=${id}&requestType=${id1}`);
    return data;
}

const interestedInApi = async (sendId, toId) => {
    const { data } = await client.get(`interest/addInterest?requestSendByUniqueCode=${toId}&requestSendToUniqueCode=${sendId}`);
    return data;
}

const deleteSocialProfile = async (id) => {
    const { data } = await client.delete(`myProfile/deleteSocialProfiles/${id}`);
    return data;
}

const deleteContact = async (id) => {
    const { data } = await client.delete(`agent/myProfile/deleteContact/${id}`);
    return data;
}

const whoDownloadedMyProfiles = async (formData) => {
    const { data } = await client.post(`agent/whoDownloadedMyProfiles/v2`, formData);
    return data;
}

const whoShortlistedMyProfiles = async (formData) => {
    const { data } = await client.post(`agent/matches/whoShortlistedMyProfiles/v2`, formData);
    return data;
}

const whoViewedMyProfiles = async (formData) => {
    const { data } = await client.post(`agent/matches/whoViewedMyProfile/v2`, formData);
    return data;
}


export {
    profileActive,
    profileSearch,
    profileDeactive,
    dashboardCount,
    uploadedProfiles,
    paidProfiles,
    shortListedProfiles,
    recentlyViewedProfiles,
    agentResendOtp,
    chevvaiDoshamProfiles,
    physicallyChallengedProfiles,
    widowerProfiles,
    reportUploadedProfiles,
    reportPaidProfiles,
    reportUploadedProfilesCsv,
    reportPaidProfilesCsv,
    preferedMatches,
    matchingProfiles,
    changeContact,
    profileView,
    allProfileStatus,
    addNewProfile,
    editPrimaryProfile,
    addFamilyDetails,
    allProfileView,
    singleMatcheProfile,
    starMatches,
    lagnaMatches,
    profileViewed,
    viewedMember,
    downloadMember,
    shortListedMember,
    addLifeStyleDetails,
    addReligiousDetails,
    addCareerDetails,
    addLocationDetails,
    addSocialMediaDetails,
    addPartnerPreferenceDetails,
    addAstroDetails,
    getUserProfile,
    getFamilyDetails,
    getLifeStyleDetails,
    getSocialDetails,
    getLocationDetails,
    getReligiousDetails,
    getEducationDetails,
    getPartnerDetails,
    getAstroDetails,
    agentMyProfiles,
    agentSubscriptions,
    getAgentProfile,
    editAgentProfile,
    changeAgentPassword,
    downloadProfileApi,
    fetchAwsImage,
    profileEnterView,
    shortlistEnterView,
    addCart,
    selectedItemsForCart,
    callBackPayment,
    getCart,
    deleteCart,
    clearCart,
    editLogo,
    editProfilePicture,
    agentVerifyOtp,
    deleteProfileImage,
    getAllNotifications,
    getMarkAsRead,
    getMarkAllAsRead,
    acceptOrRejectRequest,
    interestedInApi,
    getUploadedImages,
    deleteSocialProfile,
    deleteContact,
    dissmissStatus,
    getStatus,
    whoDownloadedMyProfiles,
    whoShortlistedMyProfiles,
    whoViewedMyProfiles
};