import React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Row, Col } from 'antd';
import JoinMe from './JoinMe'
import Image from 'helpers/Image'
import { WEB_PREFIX } from 'helpers/configs';
import Scroll from 'views/shared-components/scrolldiv/scroll';

const WebFooter = () => {
	const location = useLocation();
	const history = useHistory();

	const scrollToDiv = () => {
		if (location?.pathname === "/web/home") {
			document.getElementById('success-stories').scrollIntoView({ behavior: 'smooth', block: "start" });
		} else {
			history.push('/web/home');
			setTimeout(() => document.getElementById('success-stories').scrollIntoView({ behavior: 'smooth', block: "start" }), 300);
		}
	}

	const scrollToBranch = () => {
		if (location?.pathname === "/web/home") {
			document.getElementById('our-branches').scrollIntoView({ behavior: 'smooth', block: "start" });
		} else {
			history.push('/web/home');
			setTimeout(() => document.getElementById('our-branches').scrollIntoView({ behavior: 'smooth', block: "start" }), 300);
		}
	}


	return (
		<>
			<JoinMe />
			<footer className="sm-web-footer">
				<Scroll />
				<Row gutter={16}>
					<Col className="gutter-row" span={1}>
					</Col>
					<Col className="gutter-row" span={6} md={6} xs={12}>
						<Image name={"logo.png"} className={"foot-logo"} />
						<h5>Follow us on</h5>
						<div className="social-images">
							<Image name={"facebook.png"} className="facebook" />
							<Image name={"instagram.png"} className="instagram" />
							<Image name={"twitter.png"} className="twitter" />
							<Image name={"youtube.png"} className="youtube" />
						</div>
					</Col>
					<Col className="gutter-row" md={6}>
						<h2>Help & Support</h2>
						<ul>
							<li><a href="https://southmatrimony.tawk.help/"
								target="_blank"
								rel="noopener noreferrer">
								Feedback
							</a></li>
							<li><a href="https://southmatrimony.tawk.help/"
								target="_blank"
								rel="noopener noreferrer">
								FAQs
							</a></li>
							<li><Link to={`${WEB_PREFIX}/how-to-use-site`}>How to use this site</Link></li>
							<li><Link to={`${WEB_PREFIX}/contact-us`}>24/7 Live Help</Link></li>
							<li><Link to={`${WEB_PREFIX}/safety-tips`}>Safety Tips</Link></li>
						</ul>
					</Col>
					<Col className="gutter-row" md={5}>
						<h2>Information</h2>
						<ul>
							<li><Link to="/web/search">Partner Search</Link></li>
							<li><Link to="/web/terms-and-conditions">Terms & Conditions</Link></li>
							<li><Link to="/web/privacy-policy">Privacy Policy</Link></li>
							<li><Link to="/auth/member-registration">Register Free</Link></li>
							<li><Link to="/auth/login">Member Login</Link></li>
						</ul>
					</Col>
					<Col className="gutter-row" md={6}>
						<br></br><br></br>
						<ul>
							<li><button onClick={scrollToDiv}>Success Stories</button></li>
							<li><button onClick={scrollToBranch}>Branches</button></li>
						</ul>
					</Col>
				</Row>
			</footer>
			<Row className="mini-footer">
				<Col className="gutter-row" span={1}></Col>
				<Col span={23} className="txt-left gutter-row">
					<h5>© Copyright 2022 South Matrimony. All rights reserved.</h5>
				</Col>
			</Row>
		</>
	)
}

export default WebFooter;
