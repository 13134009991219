import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { MEMBER_PREFIX, AGENT_PREFIX } from 'helpers/configs';
import Loader from "views/shared-components/loader/Loader";
import Cookies from "js-cookie";
let role = Cookies.get("sm_role");


export const AppViews = () => {
    return (
        <Suspense fallback={<Loader />}>
            {role === "member" && (
                <>
                    <Switch>
                        <Route path={`${MEMBER_PREFIX}/how-to-use-site`} component={lazy(() => import(`../web/how-to-use-this-site`))} />
                        <Route path={`${MEMBER_PREFIX}/safety-tips`} component={lazy(() => import(`../web/safty-tips`))} />
                        <Route path={`${MEMBER_PREFIX}/terms-and-conditions`} component={lazy(() => import(`../web/terms-and-conditions`))} />
                        <Route path={`${MEMBER_PREFIX}/privacy-policy`} component={lazy(() => import(`../web/privacy-policy`))} />
                        <Route path={`${MEMBER_PREFIX}/dashboard`} component={lazy(() => import(`./user/home`))} />
                        <Route path={`${MEMBER_PREFIX}/profiles`} component={lazy(() => import(`./user/profiles`))} />
                        <Route path={`${MEMBER_PREFIX}/matches`} component={lazy(() => import(`./user/matches`))} />
                        <Route path={`${MEMBER_PREFIX}/search`} component={lazy(() => import(`./user/search`))} />
                        <Route path={`${MEMBER_PREFIX}/about-us`} component={lazy(() => import(`../web/about-us`))} />
                        <Route path={`${MEMBER_PREFIX}/contact`} component={lazy(() => import(`../web/contact-us`))} />
                        <Route path={`${MEMBER_PREFIX}/profile-view/:profile_view`} component={lazy(() => import(`../app/user/profile-view`))} />
                        <Route path={`${MEMBER_PREFIX}/mob-user/profile-view/:profile_view`} component={lazy(() => import(`../app/user/profile-view`))} />
                        <Route path={`${MEMBER_PREFIX}/cart`} component={lazy(() => import(`./user/cart`))} />
                        <Route path={`${MEMBER_PREFIX}/notification`} component={lazy(() => import(`../shared-components/Notification`))} />
                        <Route path={`${MEMBER_PREFIX}/account`} component={lazy(() => import(`./user/account`))} />
                        <Route path={`${MEMBER_PREFIX}/un-authorized`} component={lazy(() => import(`.././shared-components/un-authorized`))} />
                        <Route component={lazy(() => import(`.././shared-components/not-found`))} />
                        <Redirect from={`${MEMBER_PREFIX}`} to={`${MEMBER_PREFIX}/dashboard`} />
                    </Switch>
                </>
            )}
            {role === "agent" && (
                <Switch>
                    <Route path={`${AGENT_PREFIX}/how-to-use-site`} component={lazy(() => import(`../web/how-to-use-this-site`))} />
                    <Route path={`${AGENT_PREFIX}/safety-tips`} component={lazy(() => import(`../web/safty-tips`))} />
                    <Route path={`${AGENT_PREFIX}/terms-and-conditions`} component={lazy(() => import(`../web/terms-and-conditions`))} />
                    <Route path={`${AGENT_PREFIX}/privacy-policy`} component={lazy(() => import(`../web/privacy-policy`))} />
                    <Route path={`${AGENT_PREFIX}/success-stories`} component={lazy(() => import(`../web/success-stories`))} />
                    <Route path={`${AGENT_PREFIX}/dashboard`} component={lazy(() => import(`./agent/dashboard`))} />
                    <Route path={`${AGENT_PREFIX}/profiles`} component={lazy(() => import(`./agent/profiles`))} />
                    <Route path={`${AGENT_PREFIX}/matches`} component={lazy(() => import(`./agent/matches`))} />
                    <Route path={`${AGENT_PREFIX}/search`} component={lazy(() => import(`./agent/search`))} />
                    <Route path={`${AGENT_PREFIX}/reports`} component={lazy(() => import(`./agent/reports`))} />
                    <Route path={`${AGENT_PREFIX}/special-agent`} component={lazy(() => import(`./agent/specialAgent`))} />
                    <Route path={`${AGENT_PREFIX}/account`} component={lazy(() => import(`./agent/account`))} />
                    <Route path={`${AGENT_PREFIX}/cart`} component={lazy(() => import(`./agent/cart`))} />
                    <Route path={`${AGENT_PREFIX}/notification`} component={lazy(() => import(`../shared-components/Notification`))} />
                    <Route path={`${AGENT_PREFIX}/contact`} component={lazy(() => import(`../web/contact-us`))} />
                    <Route path={`${MEMBER_PREFIX}/un-authorized`} component={lazy(() => import(`.././shared-components/un-authorized`))} />
                    <Route component={lazy(() => import(`.././shared-components/not-found`))} />
                    <Redirect from={`${AGENT_PREFIX}`} to={`${AGENT_PREFIX}/dashboard`} />
                </Switch>

            )}
        </Suspense>
    )
}

export default AppViews;

