import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
// import { ADMIN_PREFIX, WEB_PREFIX } from 'helpers/configs';
import { AlignLeftOutlined } from "@ant-design/icons";
import { Drawer, Menu, Row, Col } from 'antd';
import Image from 'helpers/Image';


const MemberMobileNav = (props) => {
    // let history = useHistory();
    const { count } = props;
    const [open, setOpen] = useState(false);

    const showDrawer = () => {
        setOpen(true);
        // document.body.style.overflow = 'hidden';
    };

    const onClose = () => {
        setOpen(false);
        // if (typeof window != 'undefined' && window.document) {
        //     document.body.style.overflow = 'unset';
        // }
    };

    const onLogout = (e) => {
        e.preventDefault()
        onClose();
        Cookies.remove('sm_register_token');
        Cookies.remove('sm_register_domain');
        Cookies.remove('sm_register_phone');
        Cookies.remove('sm_register_otp');
        Cookies.remove('sm_role');
        Cookies.remove('profile_user_id');
        sessionStorage.clear();
        window.location.reload("/web/home");
    }


    return (
        <>
            <AlignLeftOutlined onClick={() => showDrawer()} className="drawer-icon" id="top-head" />
            <Drawer
                placement="right" onClose={onClose}
                visible={open}
                open={open}
            >
                <Row>
                    <Col xs={22}>
                        <Image name="sm1.png" className="drawer-small-logo" />
                    </Col>
                    <Col xs={2} onClick={onClose}>
                        <Image name="cross.png" className="cross-icon" />
                    </Col>
                </Row>
                <Menu
                    theme="dark"
                    mode="vertical"
                    className="sm-main-menu app-head"
                >
                    <Menu.Item key="0">
                        <Link to="/member/dashboard" onClick={onClose}>
                            <span>Home</span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="111">
                        <Link to="/member/profiles" onClick={onClose}>
                            <span>Profiles</span>
                        </Link>
                    </Menu.Item>
                    <Menu.SubMenu key="22" title="Matches" style={{ marginRight: "-1.2rem" }}>
                        <Menu.Item key="221">
                            <Link to="/member/matches/preferred-matches" onClick={onClose}>
                                <span>Preferred Matches</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="222">
                            <Link to="/member/matches/recently-viewed-profiles" onClick={onClose}>
                                <span>Recently Viewed Profiles</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="228">
                            <Link to="/member/matches/my-shortlisted-profiles" onClick={onClose}>
                                <span>My Shortlisted Profiles</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="231">
                            <Link to="/member/matches/star-lagna-match-profiles" onClick={onClose}>
                                <span>Star & Lagna Match Profiles</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="230">
                            <Link to="/member/matches/who-viewed-my-profile" onClick={onClose}>
                                <span>Who Viewed My Profile</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="223">
                            <Link to="/member/matches/who-downloaded-my-profile" onClick={onClose}>
                                <span>Who Downloaded My Profiles</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="224">
                            <Link to="/member/matches/who-shortlisted-my-profile" onClick={onClose}>
                                <span>Who Shortlisted My Profiles</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="232">
                            <Link to="/member/matches/my-downloaded-profiles" onClick={onClose}>
                                <span>My Downloaded Profiles</span>
                            </Link>
                        </Menu.Item>
                    </Menu.SubMenu>
                    <Menu.SubMenu key="33" title="Search" style={{ marginRight: "-1.2rem" }}>
                        <Menu.Item key="331">
                            <Link to="/member/search/profile-search" onClick={onClose}>
                                <span>Profile Search</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="333">
                            <Link to="/member/search/chevvai-dosham-profiles" onClick={onClose}>
                                <span>Chevvai Dosham Profiles</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="334">
                            <Link to="/member/search/physically-challenged-profiles" onClick={onClose}>
                                <span>Physically Challanged Profiles</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="335">
                            <Link to="/member/search/widower-divorcee-profiles" onClick={onClose}>
                                <span>Widower / Divorcee Profiles</span>
                            </Link>
                        </Menu.Item>
                    </Menu.SubMenu>
                    <Menu.Item key="55">
                        <Link to="/member/contact/contact-us" onClick={onClose}>
                            <span>Contact Us</span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="1123">
                        <Link to="/member/notification" onClick={onClose}>
                            <span>Notifications</span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="56">
                        <Link to="/member/cart" onClick={onClose}>
                            <span>Cart {count ? `(${count})` : ""}</span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="57">
                        <Link to="/member/account/profile-details" onClick={onClose}>
                            <span>My Profile</span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="59">
                        <Link to="/web/home" onClick={onLogout}>
                            <span>Logout</span>
                        </Link>
                    </Menu.Item>
                </Menu>
                <Image name="sm2.png" className="footer-icon" />
            </Drawer>
        </>
    )
}

export default MemberMobileNav