// import Cookies from "js-cookie";
import { message } from "antd";
import moment from "moment";
import Resizer from "react-image-file-resizer";


export const getDigitValues = (val) => val.replace(/[^\d.-]/g, '');
// export const userId = Cookies.get("profile_user_id");
export const userId = localStorage.getItem("profile_user_id");
export const user_id = localStorage.getItem("user_id");

let load;

export const setLoad = (val) => load = val;
export const getLoad = () => load;


export const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType })
    const a = document.createElement('a')
    a.download = fileName
    a.href = window.URL.createObjectURL(blob)
    const clickEvt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()
}
export const dateFormat = (date) =>
    moment(date).format("YYYY-MM-DD");

export const birthDateFormat = (date) =>
    moment(date).format("DD-MM-YYYY");

export const planDateFormat = (date) =>
    moment(date).format("DD MMM YYYY , hh:mm A");

export const hourFormat = (date) =>
    moment(date).format("hh:mm A");

export const changeSmallString = (str) => str?.toLowerCase();

export const exportToJson = (dataCsv, fileName) => {
    downloadFile({
        data: dataCsv,
        fileName: fileName,
        fileType: 'text/csv',
    })
}


export const changeFileName = (data, str) => {
    if (data?.contact && data?.photo) {
        return str + " AP";
    } if (data?.contact) {
        return str + " A";
    } if (data?.photo) {
        return str + " P";
    } if (!data?.contact && !data?.photo) {
        return str;
    }
}


export const rasiStatus = (data) => {
    let arr = [];
    for (const [key, value] of Object.entries(data)) {
        if (key?.includes("rasi") || key?.includes("amsam")) {
            if (value !== null) {
                arr.push(true);
            } else {
                arr.push(false)
            }
        }
    }
    const newArr = arr?.every((item) => item === false);
    return newArr;
}
export const lifeStyleStatus = (data) => {
    let a = data?.lifeStyleDto;
    let b = data?.basicAndAboutHerDto;
    let arr = [];
    for (const value of Object.values(a)) {
        if (value !== null) {
            arr.push(true);
        } else {
            arr.push(false);

        }
    }
    for (const [key, value] of Object.entries(b)) {
        if (key === "bodyType" || key === "physicalStatus") {
            if (value !== null) {
                value === 0 ? arr.push(false) : arr.push(true);
            } else {
                arr.push(false);
            }
        }
    }
    const newArr = arr?.every((item) => item === false);

    return newArr;
}
export const educationStatus = (data) => {
    let arr = [];
    for (const [key, value] of Object.entries(data)) {
        if (key === "highestEducation" || key === "salary" || key === "occupation") {
            if (value !== null) {
                value === 0 ? arr.push(false) : arr.push(true);
            } else {
                arr.push(false);
            }
        }
    }
    const newArr = arr?.every((item) => item === false);
    return newArr;
}
export const aboutStatus = (data) => {
    let arr = [];
    for (const [key, value] of Object.entries(data)) {
        if (key === "aboutHer") {
            if (value !== null) {
                arr.push(true);
            } else {
                arr.push(false);
            }
        }
    }
    const newArr = arr?.every((item) => item === false);
    return newArr;
}
export const religionStatus = (data) => {
    let arr = [];
    for (const [key, value] of Object.entries(data)) {
        if (key === "religion" || key === "caste") {
            if (value !== null) {
                arr.push(true);
            } else {
                arr.push(false);
            }
        }
    }
    const newArr = arr?.every((item) => item === false);
    return newArr;
}

const uppercaseWords = (str) => str.replace(/^(.)|\s+(.)/g, (c) => c.toUpperCase())

export const profileCompleteStatus = (data) => {
    let arr = [];
    if (Object.keys(data)?.length !== 0) {
        for (const [key, value] of Object.entries(data)) {
            if (!value && key !== "images") {
                arr.push(uppercaseWords(key));
            }
        }
    }
    return arr;
}
export const clientId = process.env.REACT_APP_CLIENT_ID;

export const resizeFile = (file) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            "1200",
            "1200",
            "JPEG",
            1000,
            0,
            (uri) => {
                resolve(uri);
            },
            "base64"
        );
    });

export const dataURIToBlob = (dataURI) => {
    const splitDataURI = dataURI.split(",");
    const byteString =
        splitDataURI[0].indexOf("base64") >= 0
            ? atob(splitDataURI[1])
            : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);
    return new Blob([ia], { type: mimeString });
};

const TOKEN = sessionStorage.getItem("sm_register_token");


export const checkHeight = (str) => {
    return str?.startsWith("0") ? "" : ", " + str;
}
export const checkHeightForView = (str) => {
    return str?.startsWith("0") ? "" : str;
}

export function subtractYears(numOfYears, date = new Date()) {
    date.setFullYear(date.getFullYear() - numOfYears);
    return moment(date).format("YYYY-MM-DD");
}

export const viewAwsDoc = async (imgPath) => {
    await fetch(`https://api.southmatrimony.com/getFileStream?path=${imgPath}`, {
        method: "get",
        headers: {
            "Authorization": `Bearer ${TOKEN}`,
        }
    })
        .then(response => response.blob())
        .then(blob => {
            const fileURL = window.URL.createObjectURL(blob);
            let alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = "file.pdf";
            alink.click();
        })
}

export const getLagnaMatchResult = (val) => {
    if (val === 3) {
        return "சிறப்பு"
    } else if (val === 2) {
        return "மத்திமம்"
    } else if (val === 1) {
        return "இல்லை"
    }
}

export const getLagnaMatchInEng = (val) => {
    if (val === 3) {
        return "lagna-yes-btn";
    } else if (val === 2) {
        return "lagna-medium-btn";
    } else if (val === 1) {
        return "lagna-no-btn";
    }
}

export const getLagnaMatchContent = (val) => {
    if (val === 3) {
        return "Yes";
    } else if (val === 2) {
        return "Medium";
    } else if (val === 1) {
        return "No";
    }
}

export const getLagnaMatchResultStatus = (val) => {
    if (val === 3) {
        return "பொருத்தம் உண்டு"
    } else if (val === 2) {
        return "பொருத்தம் உண்டு"
    } else if (val === 1) {
        return "இல்லை"
    }
}

export const showYear = (val) => {
    if (val === 1) {
        return `${val} வருடம், `;
    } else if (val > 1) {
        return `${val} வருடங்கள், `;
    } else if (val === 0 || val === null) {
        return "";
    }
}

export const showMonth = (val) => {
    if (val === 1) {
        return `${val} மாதம், `;
    } else if (val > 1) {
        return `${val} மாதங்கள், `;
    } else if (val === 0 || val === null) {
        return "";
    }
}

export const showDate = (val) => {
    if (val === 1) {
        return `${val} நாள், `;
    } else if (val > 1) {
        return `${val} நாட்கள்`;
    } else if (val === 0 || val === null) {
        return ""
    }
}


export const partnerPoints = data => {
    let cur = 0;
    for (const value of Object.values(data)) {
        if (value) {
            cur++
        }
    }
    return cur
}

export function camelize(str) {
    let newStr = str && str?.toLowerCase()?.split(' ')
        .map(
            (word) => {
                let a = word[0]?.toUpperCase() + word?.slice(1);
                return a !== "undefined" ? a : "";
            }
        )
        .join(' ');
    let digitStr = newStr && newStr.split('.')
        .map(
            (word) => {
                let a = word[0]?.toUpperCase() + word?.slice(1);
                return a !== "undefined" ? a : "";
            }
        )
        .join('.');

    return digitStr;
    // str?.toLowerCase()
    //     .split(' ')
    //     .map(
    //         (word) => {
    //             let a = word[0]?.toUpperCase() + word?.slice(1);
    //             return a !== "undefined" ? a : "";
    //         }
    //     )
    //     .join(' ');

}

export const validateHandler = (regNo, values) => {
    if (!regNo) {
        message.error("Please fill the For Whom field");
        return false;
    } if (!values) {
        message.error("Add Atleast One register number to download profile");
        return false;
    }
    if (Object.values(values).includes(undefined)) {
        message.error("Please Fill the required fields");
        return false;
    }
    return true;
}

