import React, { Fragment, useEffect } from "react";
import { useAwsImage } from "hooks/agent/Profiles";
import { Skeleton } from 'antd';

const PrivateImage = ({ className, privateUrl, str, onClick = null, val }) => {
    const { data, refetch, isLoading } = useAwsImage(privateUrl);

    useEffect(() => {
        if (privateUrl !== undefined) {
            refetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [privateUrl]);



    return (
        <Fragment>
            {
                !isLoading && privateUrl !== undefined && data !== undefined ?
                    <img className={className}
                        loading="lazy"
                        decoding="async"
                        src={data} alt="...." onClick={onClick} /> :
                    <Skeleton.Avatar active="true" size="large"
                        shape={str === "full-size" ? "square" : "circle"}
                        style={{
                            width: str === "full-size-header" ? "20" : "inherit",
                            height: str === "full-size-header" ? "2.5rem" : "inherit",
                            marginTop: str === "full-size-header" && val ? "-10px" : "inherit",
                            marginRight: str === "full-size-header" && "5px",
                            borderRadius: str === "full-size-header" && "5px"
                        }}
                        className={str !== "full-size-header" && className} />
            }
        </Fragment>
    )
};

export default PrivateImage;