import React from "react";
import WebLayout from "layouts/web-layout";
import AppLayout from "layouts/app-layout";
import AuthLayout from "layouts/auth-layout";
import { Switch, Route, Redirect } from "react-router-dom";
import { WEB_PREFIX, AUTH_PREFIX, MEMBER_PREFIX, AGENT_PREFIX, SUPERADMIN_PREFIX } from "helpers/configs";
import Cookies from "js-cookie";

function AppInterceptor({ children, isAuthenticated, prefix, ...rest }) {
    return (
        <Route
            {...rest}
            render={({ location }) => isAuthenticated ? (
                children
            ) : (
                <Redirect to={{ pathname: prefix, state: { from: location } }} />
            )
            }
        />
    );
}


let role = Cookies.get("sm_role");

export const Views = (props) => {
    const { location } = props;

    let PREFIX = "";

    if (role === "member") {
        PREFIX = MEMBER_PREFIX;
    }
    else if (role === "admin") {
        PREFIX = SUPERADMIN_PREFIX;
    }
    else if (role === "agent") {
        PREFIX = AGENT_PREFIX;
    }

    // let locationSplit = location.pathname.split('/')
    // let intruderRole = locationSplit[1]
    // if(locationSplit!==undefined){
    //     if(locationSplit.length >= 3){
    //         if (intruderRole !== isRole) {
    //            return <Redirect to={`/${isRole}/un-authorized`} />;
    //         }
    //     }
    // }

    return (
        <Switch>
            <Route exact path="/">
                <Redirect to={WEB_PREFIX} />
            </Route>
            <Route path={WEB_PREFIX}>
                <WebLayout />
            </Route>
            <Route path={AUTH_PREFIX}>
                <AuthLayout />
            </Route>
            <AppInterceptor path={PREFIX} isAuthenticated={sessionStorage.getItem("sm_register_token")} prefix={PREFIX}>
                <AppLayout location={location} />
            </AppInterceptor>
        </Switch>
    )
}

export default Views;