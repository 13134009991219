import React, { useEffect, useState } from 'react';
import { Button, Col, message, Popover, Row } from 'antd';
import Image from 'helpers/Image'
import { useAcceptorReject, useGetAllAsRead, useGetAsRead, useGetNotifications } from 'hooks/agent/Notification';
import moment from 'moment';
import PrivateImage from 'helpers/privateImage';

const NotifyBody = () => {
  const [ids, setIds] = useState(null);
  const [click, setClick] = useState(false);
  const [id, setId] = useState(null);
  const [interestId, setInterestId] = useState(null);
  const [acceptId, setAcceptId] = useState(null);
  const { data, refetch } = useGetNotifications();
  const { data: markData, refetch: refetchMarkData } = useGetAsRead(id);
  const { data: markAllData, refetch: refetchMarkAllData } = useGetAllAsRead(ids);
  const { data: acceptData, refetch: refetchAcceptRequest } = useAcceptorReject(interestId, acceptId);

  const markRead = (item) => {
    setId(item?.notificationId);
  }
  const clickHandler = () => setClick(!click);
  const markAllRead = () => {
    if (data?.data?.length > 0) {
      const ids = data !== undefined && data?.data?.length > 0 && data?.data?.map((item) => item.notificationId)?.join(",");
      setIds(ids);
    }
  }
  const acceptOrRejectFunc = (item, str) => {
    if (str === "accept") {
      setInterestId(item?.notificationId);
      setAcceptId(2);
    } else {
      setInterestId(item?.notificationId);
      setAcceptId(3);
    }
  }
  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (id !== null) {
      refetchMarkData();
    }
    if (ids !== null) {
      refetchMarkAllData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, ids]);
  useEffect(() => {
    if (acceptId !== null && interestId !== null) {
      refetchAcceptRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptId, interestId]);

  useEffect(() => {
    if (markData !== undefined) {
      if (markData?.status === 200) {
        message.success(markData?.data);
        setId(null);
        refetch();
      } else {
        message.error(markData?.data);
      }
    }
    if (markAllData !== undefined) {
      if (markAllData?.status === 200) {
        message.success(markAllData?.data);
        setIds(null);
        refetch();
      } else {
        message.error(markAllData?.data);
      }
    }
    if (acceptData !== undefined) {
      if (acceptData?.status === 200) {
        message.success(acceptData?.data);
        setInterestId(null);
        setAcceptId(null);
        refetch();
      } else {
        message.error(acceptData?.data);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markData, markAllData, acceptData]);



  const content = (
    <div className="pop-body-ant notify-body" >
      <div className='notify-head'>
        <h3>Notifications</h3>
        <Button onClick={() => markAllRead()}>Mark all as read</Button>
      </div>
      {data !== undefined && data?.data !== null ? data?.data?.map((item, i) => (
        <div key={i} className="notify-item">
          <Row>
            <Col md={3} xs={3}>
              <PrivateImage className="notify-img" privateUrl={item?.image} str="half-size" onClick={() => markRead(item)} />
            </Col>
            <Col md={14} xs={14}>
              <h4 onClick={() => markRead(item)}>{item?.title}</h4>
              <p onClick={() => markRead(item)}>{item?.messageBody}</p>
              {item?.statusType === 1 && <Button className='accept-btn' onClick={() => acceptOrRejectFunc(item, "accept")}>ACCEPT</Button>}
              {item?.statusType === 1 && <Button className='reject-btn' onClick={() => acceptOrRejectFunc(item, "reject")}>REJECT</Button>}
            </Col>
            <Col md={7} xs={7}>
              <p onClick={() => markRead(item)}>{moment(item?.createdAt).fromNow()}</p>
            </Col>
          </Row>
        </div>)) : <h1 className='empty-data'>No Data Found</h1>}
    </div >
  )
  return (
    <Popover placement="bottom" content={content} trigger="click" >
      {click ?
        <div onClick={() => clickHandler()} className="notify-div">
          <Image name={"bell.svg"} className="bell-icon" />
          {data !== undefined && data?.data !== null && data?.data?.length > 0 && <span className='notify-count' >{data?.data?.length}</span>}
        </div>
        :
        <div onClick={() => clickHandler()} className="notify-div">
          <Image name={"bell2.svg"} className="bell-icon" onClick={() => clickHandler()} />
          {data !== undefined && data?.data !== null && data?.data?.length > 0 && <span className='notify-count' >{data?.data?.length}</span>}
        </div>
      }
    </Popover>
  )
}

export default NotifyBody;