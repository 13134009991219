import { useMutation, useQuery, useQueryClient } from "react-query";
import * as api from "services/AgentServices";
import * as imgApi from "services/ImgServices";

export const useAgentUploadedProfiles = () => {
    return useMutation((formData) => api.uploadedProfiles(formData));
}

export const useAgentPaidProfiles = () => {
    return useMutation((formData) => api.paidProfiles(formData));
}
export const useMatchingProfiles = () => {
    return useMutation((formData) => api.matchingProfiles(formData));
}
export const useAllProfileView = (id) => {
    return useQuery(["AllProfileView", id], () => api.allProfileView(id), {
        enabled: false,
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
    });
}
export const useDismissStatus = (id) => {
    return useQuery(["DismissStatus", id], () => api.dissmissStatus(id), {
        enabled: false,
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
    });
}
export const useGetStatus = (id) => {
    return useQuery(["GetStatus", id], () => api.getStatus(id), {
        enabled: false,
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
    });
}
export const useProfileStatus = (id) => {
    return useQuery(["ProfileStatus", id], () => api.allProfileStatus(id), {
        enabled: false,
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
    });
}
export const useGetUserProfile = (id) => {
    return useQuery(["UserProfile", id], () => api.getUserProfile(id), {
        enabled: false,
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
    });
}
export const useFamilyDetails = (id) => {
    return useQuery(["FamilyDetails", id], () => api.getFamilyDetails(id), {
        enabled: false,
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
    });
}
export const useLifeStyleInfo = (id) => {
    return useQuery(["LifeStyleInfo", id], () => api.getLifeStyleDetails(id), {
        enabled: false,
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
    });
}
export const useLocationInfo = (id) => {
    return useQuery(["LocationInfo", id], () => api.getLocationDetails(id), {
        enabled: false,
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
    });
}
export const useSocialInfo = (id) => {
    return useQuery(["SocialInfo", id], () => api.getSocialDetails(id), {
        retry: false,
        enabled: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
    });
}
export const useReligiousInfo = (id) => {
    return useQuery(["ReligiousInfo", id], () => api.getReligiousDetails(id), {
        enabled: false,
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
    });
}
export const useEducationInfo = (id) => {
    return useQuery(["EducationInfo", id], () => api.getEducationDetails(id), {
        enabled: false,
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
    });
}
export const usePartnerInfo = (id) => {
    return useQuery(["PartnerInfo", id], () => api.getPartnerDetails(id), {
        enabled: false,
        refetchInterval: false,
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
    });
}
export const useAstroInfo = (id) => {
    return useQuery(["AstroInfo", id], () => api.getAstroDetails(id), {
        enabled: false,
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
    });
}
export const useUploadedImages = (id) => {
    return useQuery(["GetUploadedImages", id], () => api.getUploadedImages(id), {
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
    });
}
export const useSingleMatcheProfile = (id) => {
    return useQuery(["SingleMatcheProfile", id], () => api.singleMatcheProfile(id), {
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
    });
}
export const usePreferedMatches = (id, page) => {
    return useQuery(["PreferedMatches", id, page], () => api.preferedMatches(id, page), {
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
    });
}
export const useStarMatches = (id, page) => {
    return useQuery(["StarMatches", id, page], () => api.starMatches(id, page), {
        enabled: false,
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
    });
}
export const useLagnaMatches = (id, page) => {
    return useQuery(["LagnaMatches", id, page], () => api.lagnaMatches(id, page), {
        enabled: false,
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
    });
}
export const useProfileViewed = (id, page) => {
    return useQuery(["ProfileViewed", id, page], () => api.viewedMember(id, page), {
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
    });
}
export const useViewedMember = (id, page) => {
    return useQuery(["ViewedMember", id, page], () => api.viewedMember(id, page), {
        enabled: false,
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
    });
}
export const useDownloadMember = (id, page) => {
    return useQuery(["DownloadMember", id, page], () => api.downloadMember(id, page), {
        enabled: false,
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
    });
}
export const useShortListedMember = (id, page) => {
    return useQuery(["ShortListedMember", id, page], () => api.shortListedMember(id, page), {
        enabled: false,
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
    });
}
export const useAddNewProfile = () => {
    return useMutation((formData) => api.addNewProfile(formData));
}
export const useEditNewProfile = (id) => {
    return useMutation((formData) => {
        return api.editPrimaryProfile(formData, id);
    });
}
export const useNewFamilyDetails = () => {
    return useMutation((formData) => api.addFamilyDetails(formData));
}
export const useLifeStyleDetails = () => {
    return useMutation((formData) => api.addLifeStyleDetails(formData));
}
export const useReligionDetails = (id) => {
    return useMutation((formData) => {
        return api.addReligiousDetails(formData, id);
    });
}
export const useCareerDetails = () => {
    return useMutation((formData) => api.addCareerDetails(formData));
}
export const useLocationDetails = () => {
    return useMutation((formData) => api.addLocationDetails(formData));
}
export const useSocialMediaInfo = (id) => {
    return useMutation((formData) => {
        return api.addSocialMediaDetails(formData, id);
    });
}
export const usePartnerPreference = () => {
    return useMutation((formData) => api.addPartnerPreferenceDetails(formData));
}
export const useAstroDetails = (id) => {
    return useMutation((formData) => {
        return api.addAstroDetails(formData, id)
    });
}
export const useDownloadProfile = (imgPath) => {
    return useQuery(["fetchAwsDoc", imgPath], () => imgApi.fetchAwsPdf(imgPath), {
        retry: false,
        enabled: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
    });
}
export const useAwsImage = (imgPath) => {
    return useQuery(["AwsProfileImages", imgPath], () => imgApi.fetchAwsImage(imgPath), {
        refetchOnWindowFocus: false,
        retry: false,
        enabled: false,
        refetchOnReconnect: true,
    });
}
export const useAwsPdf = (imgPath) => {
    return useQuery(["AwsAstroPdf", imgPath], () => imgApi.viewAwsDoc(imgPath), {
        refetchOnWindowFocus: false,
        retry: false,
        enabled: false,
        refetchOnReconnect: true,
    });
}

export const useAwsImages = (imgPath) => {
    return useQuery(["publicAwsProfileImages", imgPath], () => imgApi.publicfetchAwsImage(imgPath), {
        refetchOnWindowFocus: false,
        retry: false,
        enabled: false,
        refetchOnReconnect: true,
    });
}
export const useShortlistEnterView = (id1, id2, str) => {
    const queryClient = useQueryClient();
    return useMutation(["ShortlistEnterView", id1, id2], () => api.shortlistEnterView(id1, id2), {
        onSuccess: () => {
            if (str === "download_member") {
                queryClient.refetchQueries('DownloadMember');
            } else if (str === "shortlist_member") {
                queryClient.refetchQueries('ShortListedMember');
            } else if (str === "lagna_Match") {
                queryClient.refetchQueries('LagnaMatches');
            } else if (str === "profile_viewed") {
                queryClient.refetchQueries('ProfileViewed');
            } else if (str === "star_match") {
                queryClient.refetchQueries('StarMatches');
            } else if (str === "viewed_member") {
                queryClient.refetchQueries('ViewedMember');
            }
        }
    });
}
export const useEditProfilePicture = () => {
    const queryClient = useQueryClient();
    return useMutation((formData) => api.editProfilePicture(formData), {
        onSuccess: () => {
            queryClient.refetchQueries('AgentMyProfiles');
        }
    });
}
export const useEditLogo = () => {
    const queryClient = useQueryClient();
    return useMutation((formData) => api.editLogo(formData), {
        onSuccess: () => {
            queryClient.refetchQueries('AgentMyProfiles');
        }
    });
}
export const useDeleteProfileImage = () => {
    const queryClient = useQueryClient();
    return useMutation((id) => api.deleteProfileImage(id), {
        onSuccess: () => {
            queryClient.refetchQueries('UserProfile');
        },
    });
}
export const useDeleteSocialProfile = () => {
    const queryClient = useQueryClient();
    return useMutation((id) => api.deleteSocialProfile(id), {
        onSuccess: () => {
            queryClient.refetchQueries('SocialInfo');
        },
    });
}
export const useDeleteContactNumber = () => {
    return useMutation((id) => api.deleteContact(id));
}
export const useAgentWhoDownloadedMyProfile = () => {
    return useMutation((formData) => api.whoDownloadedMyProfiles(formData));
}
export const useAgentWhoShortlistedMyProfile = () => {
    return useMutation((formData) => api.whoShortlistedMyProfiles(formData));
}
export const useAgentWhoViewedMyProfile = () => {
    return useMutation((formData) => api.whoViewedMyProfiles(formData));
}

