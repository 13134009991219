import React from 'react';
import Image from 'helpers/Image';
import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom';
let role = Cookies.get("sm_role");

const Scroll = () => {
    const location = useLocation();

    const scrollToDiv = () => {
        if (location.pathname.includes("mob")) {
            document.getElementById('mob-terms')?.scrollIntoView({ behavior: 'smooth', block: "start" });
        } else {
            let div = role ? document.getElementById('top-head') : document.getElementById('new-top');
            div?.scrollIntoView({ behavior: 'smooth', block: "start" });
        }
    }

    return (
        <div className='sm-page role-page'>
            <button onClick={scrollToDiv} className="arg"><Image name={"arp.svg"} className="arp" /></button>
        </div>
    )
}

export default Scroll;
