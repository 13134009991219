import { Row, Col, Menu, Layout, Button } from 'antd'
import { Link, useHistory } from 'react-router-dom'
import Cookies from 'js-cookie'
import Image from 'helpers/Image'
import WebMobileNav from '../common/WebMobileNav'

const { Header } = Layout

const WebHeader = () => {
	let history = useHistory();
	const onLogin = (e) => {
		e.preventDefault()
		sessionStorage.removeItem('sm_register_token')
		Cookies.remove('sm_register_domain')
		Cookies.remove('sm_register_phone')
		Cookies.remove('sm_register_otp')
		Cookies.remove('sm_role');
		Cookies.remove('google-mail');
		Cookies.remove('google-data');
		history.push("/auth/login");
	}

	const removeGoogleData = () => {
		Cookies.remove('google-mail');
		Cookies.remove('google-data');
	}

	return (
		<header className="sm-app-header" id="new-top">
			<Row>
				<Col md={5} xs={0}>
					<Link to="/web/home">
						<Image name={"logo.png"} className="logo logo-img" />
					</Link>
				</Col>
				<Col md={0} xs={15}>
					<Image name="sm1.png" className="sm-logo" />
				</Col>
				<Col md={0} xs={3} onClick={(e) => onLogin(e)}>
					<Button className='mob-login-btn' >
						<Image name="user.png" />Login</Button>
				</Col>
				<Col md={0} xs={3} />
				<Col md={0} xs={3}>
					<WebMobileNav />
				</Col>
				<Col md={14} xs={0}>
					<Header>
						<Menu
							theme="dark"
							mode="horizontal"
							defaultSelectedKeys={['0']}
							className="sm-main-menu"
						//items={MenuItems}
						>
							<Menu.Item key="0">
								<Link to="/web/home">
									<span>Home</span>
								</Link>
							</Menu.Item>
							<Menu.Item key="1">
								<Link to="/web/about-us">
									<span>About Us</span>
								</Link>
							</Menu.Item>
							<Menu.Item key="2">
								<Link to="/web/profiles">
									<span>Profiles</span>
								</Link>
							</Menu.Item>
							<Menu.Item key="3">
								<Link to="/web/search">
									<span>Search</span>
								</Link>
							</Menu.Item>
							<Menu.Item key="4">
								<Link to="/web/contact-us">
									<span>Contact Us</span>
								</Link>
							</Menu.Item>
						</Menu>
					</Header>
				</Col>
				<Col md={5} xs={0}>
					<ul className="ul-horizontal">
						<li>
							<Link className="sm-btn sm-btn-login" to="/auth/login" onClick={(e) => onLogin(e)}>Login</Link>
						</li>
						<li>
							<Link to="/auth/member-registration" className="sm-btn sm-btn-register" onClick={() => removeGoogleData()}>Register</Link>
							{/* <Link className="sm-btn sm-btn-register" to="/auth/member-registration">Register</Link> */}
						</li>
					</ul>
				</Col>
			</Row>
		</header>
	)
}

export default WebHeader