import React, { useEffect } from 'react';
import { useGoogleLogout } from 'react-google-login';
import { Row, Col, Menu, Layout } from 'antd'
import { Dropdown, Space } from 'antd';
import PrivateImage from 'helpers/privateImage';
import { clientId } from 'helpers/commonFunctions';
import { Link } from 'react-router-dom'
import Image from 'helpers/Image'
import Cookies from "js-cookie";
import { useAgentMyProfiles } from 'hooks/agent/MyProfiles';
import NotifyBody from 'views/shared-components/popover/NotifyBody';
import MobileNav from './MobileNav';
import { camelize } from 'helpers/commonFunctions';
const { Header } = Layout;
let sp_agent = Cookies.get('specialAgent');


const CommonHeader = () => {

	const { data: domain, refetch } = useAgentMyProfiles();

	useEffect(() => {
		refetch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const menu = (
		<Menu
			items={[
				{
					label: (
						<Link to="/agent/account/profile-details">
							<h4><div className='profile-tops'><Image name={"user.svg"} className={"icon-img"} />My Profile</div></h4>
						</Link>
					),
					key: '0',
				},
				{
					label: (
						<h4 onClick={(e) => onLogout(e)} ><div className='profile-to'><Image name={"out.svg"} className={"icon-ig"} />Log Out</div></h4>
					),
					key: '1',
				},
			]}
		/>
	);

	const removeStorage = () => {
		Cookies.remove('sm_register_token');
		Cookies.remove('sm_register_domain');
		Cookies.remove('sm_register_phone');
		Cookies.remove('sm_register_otp');
		Cookies.remove('sm_role');
		Cookies.remove('profile_user_id');
		Cookies.remove('specialAgent');
		Cookies.remove('google-mail');
		Cookies.remove('google-data');
		Cookies.remove("mob");
		sessionStorage.clear();
		window.location.reload("/web/home");
	}

	const { signOut } = useGoogleLogout({
		clientId: clientId,
	})

	const onLogout = (e) => {
		e.preventDefault()
		signOut();
		removeStorage();
	}



	return (
		<header className="sm-app-header app-page mob-head" id="top-head">
			<Row>
				<Col md={5} xs={22}>
					<Link to="/agent/dashboard">{domain !== undefined && domain?.data?.logoImage !== null ? <PrivateImage privateUrl={domain?.data?.logoImage} className="logo-header" />
						: <Image name={"avatar-man.png"} className={"logo-header"} />} </Link>
					<Link to="/agent/dashboard"><h1 >{domain?.data?.matrimonyName && camelize(domain?.data?.matrimonyName)}</h1></Link>
				</Col>
				<Col md={14} xs={0}>
					<Header>
						<Menu
							theme="dark"
							mode="horizontal"
							//defaultSelectedKeys={['0']}
							className="sm-main-menu"
						//items={MenuItems}
						>
							<Menu.Item key="0">
								<Link to="/agent/dashboard">
									<span>Dashboard</span>
								</Link>
							</Menu.Item>
							<Menu.SubMenu key="11" title="Profiles">
								<Menu.Item key="111">
									<Link to="/agent/profiles/uploaded-profiles">
										<span>Uploaded Profiles</span>
									</Link>
								</Menu.Item>
								<Menu.Item key="112">
									<Link to="/agent/profiles/paid-profiles">
										<span>Paid Profiles</span>
									</Link>
								</Menu.Item>
							</Menu.SubMenu>
							<Menu.SubMenu key="22" title="Matches">
								<Menu.Item key="221">
									<Link to="/agent/matches/my-shortlisted-profiles">
										<span>My Shortlisted Profiles</span>
									</Link>
								</Menu.Item>
								<Menu.Item key="222">
									<Link to="/agent/matches/who-viewed-my-profile">
										<span>Who Viewed My Profiles</span>
									</Link>
								</Menu.Item>
								<Menu.Item key="223">
									<Link to="/agent/matches/who-downloaded-my-profile">
										<span>Who Downloaded My Profiles</span>
									</Link>
								</Menu.Item>
								<Menu.Item key="224">
									<Link to="/agent/matches/who-shortlisted-my-profile">
										<span>Who Shortlisted My Profiles</span>
									</Link>
								</Menu.Item>
							</Menu.SubMenu>
							<Menu.SubMenu key="33" title="Search">
								<Menu.Item key="331">
									<Link to="/agent/search/profile-search">
										<span>Profile Search</span>
									</Link>
								</Menu.Item>
								<Menu.Item key="332">
									<Link to="/agent/search/recently-viewed-profiles">
										<span>Recently Viewed Profiles</span>
									</Link>
								</Menu.Item>
								<Menu.Item key="333">
									<Link to="/agent/search/chevvai-dosham-profiles">
										<span>Chevvai Dosham Profiles</span>
									</Link>
								</Menu.Item>
								<Menu.Item key="334">
									<Link to="/agent/search/physically-challenged-profiles">
										<span>Physically Challenged Profiles</span>
									</Link>
								</Menu.Item>
								<Menu.Item key="335">
									<Link to="/agent/search/widower-divorcee-profiles">
										<span>Widower / Divorcee Profiles</span>
									</Link>
								</Menu.Item>
							</Menu.SubMenu>
							<Menu.Item key="44">
								<Link to="/agent/reports/uploaded-profiles">
									<span>Reports</span>
								</Link>
							</Menu.Item>
							{sp_agent && +sp_agent === 1 && <Menu.Item key="66">
								<Link to="/agent/special-agent">
									<span>Special Agent</span>
								</Link>
							</Menu.Item>}
							<Menu.Item key="55">
								<Link to="/agent/contact/contact-us">
									<span>Contact Us</span>
								</Link>
							</Menu.Item>
						</Menu>
					</Header>
				</Col>
				<Col md={0} xs={2} className="txt-right">
					<MobileNav sp_agent={+sp_agent} count={domain?.data?.cartCount} />
				</Col>
				<Col md={5} xs={0} style={{ marginTop: "-5px" }}>
					<Link to="/agent/cart">
						{domain?.data?.cartCount > 0 && <p className='cart-count'>{domain?.data?.cartCount}</p>}
						<Image name={"bags.svg"} className={"bags"} />&nbsp;&nbsp;
					</Link>
					<NotifyBody />
					<Dropdown overlay={menu} className="drop-data">
						<Space onClick={(e) => e.preventDefault()}>
							&nbsp;&nbsp;&nbsp;&nbsp;
							<div className='profile-top'>
								{domain !== undefined && domain?.data?.profileImage !== null ? <PrivateImage privateUrl={domain?.data?.profileImage} str="full-size-header" val={domain?.data?.registrationName} className={domain?.data?.registrationName ? "profile-top-img space-margin" : "profile-top-img add-spacee"} />
									:
									<Image name={"avatar-man.png"} className={domain?.data?.registrationName ? "profile-top-dummy" : "profile-top-dummy add-space"} />
								}
								<span>{domain?.data?.registrationName && camelize(domain?.data?.registrationName)}</span>
							</div>
							<Image name={"poly.svg"} className={"poly-arrow"} />
						</Space>
					</Dropdown>
				</Col>
			</Row>
		</header>
	)
}

export default CommonHeader