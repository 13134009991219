import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// import { ADMIN_PREFIX, WEB_PREFIX } from 'helpers/configs';
import { AlignLeftOutlined } from "@ant-design/icons";
import { Drawer, Menu } from 'antd';
import Image from 'helpers/Image';
import "./style.css";


const WebMobileNav = () => {
    const [open, setOpen] = useState(false);

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    return (
        <>
            <AlignLeftOutlined onClick={() => showDrawer()} className="drawer-web-icon" id="top-head" />
            <Drawer
                title={
                    <Image name="sm1.png" className="drawer-small-logo" onClose={onClose} />
                }
                placement="right" onClose={onClose}
                visible={open}
                open={open}
            >
                <Menu
                    theme="dark"
                    mode="vertical"
                    className="sm-main-menu"
                >
                    <Menu.Item key="0">
                        <Link to="/web/home" onClick={onClose}>
                            <span>Home</span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="1">
                        <Link to="/web/about-us" onClick={onClose}>
                            <span>About Us</span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="2">
                        <Link to="/web/profiles" onClick={onClose}>
                            <span>Profiles</span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="3">
                        <Link to="/web/search" onClick={onClose}>
                            <span>Search</span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="4">
                        <Link to="/web/contact-us" onClick={onClose}>
                            <span>Contact Us</span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="6">
                        <Link to="" onClick={onClose}>
                            <span>Register</span>
                        </Link>
                    </Menu.Item>
                </Menu>
                <Image name="sm2.png" className="footer-icon" />
            </Drawer>
        </>
    )
}

export default WebMobileNav;