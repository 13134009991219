import React, { Suspense } from "react";
import { BrowserRouter as Router, Switch, Route, useHistory } from "react-router-dom";
import Views from "views";
import Loader from "views/shared-components/loader/Loader";
import ScrollToTop from "views/shared-components/ScrollToTop";
import { useState } from 'react';
import { useEffect } from 'react';
import { Modal } from 'antd';
import Log from "assets/gg.png";

function App() {
    let history = useHistory();
    const [open, setOpen] = useState(false);
    const close = () => setOpen(false);
    
    useEffect(() => {
        if (window.innerWidth < 720) {
            setOpen(true);
        }
    }, []);

    return (
        <>
            <Suspense fallback={<Loader />}>
                <Router history={history}>
                    <ScrollToTop />
                    <Switch>
                        <Route path="/" component={Views} />
                    </Switch>
                </Router>
            </Suspense>
            <Modal
                centered
                visible={open}
                onOk={close}
                onCancel={close}
                closable={false}
                width={330}
            >
                <div className='txt-center'>
                    <h3>Download the app in Playstore</h3>
                    <a href="https://play.google.com/store/apps/details?id=com.corpfield.southmatrimony" target="_">
                        <img src={Log} alt="..." className="play-store" width="auto" height="auto" />
                    </a>
                </div>

            </Modal>
        </>
    );
}

export default App;
