import React from 'react';
import "./style.css";

const Loader=()=>{
	return (
		<>
		<div className='rounds'>
			<div className="loader">
				<div className="ball"></div>
				<div className="ball"></div>
				<div className="ball"></div>
				<div className="ball"></div>
			</div>
			<div className="loader">
				<div className="mirror">
					<div className="ball"></div>
					<div className="ball"></div>
					<div className="ball"></div>
					<div className="ball"></div>
				</div>
			</div>
		</div> 
		</>
	)
}

export default Loader;
