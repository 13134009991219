import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "services/AgentServices";

export const useAgentMyProfiles = () => {
    return useQuery("AgentMyProfiles", () => api.agentMyProfiles(), {
        enabled: false,
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
    });
}
export const useAgentSubscriptions = () => {
    return useQuery("AgentSubscriptions", () => api.agentSubscriptions(), {
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
    });
}
export const useAgentProfile = () => {
    return useQuery("AgentProfile", () => api.getAgentProfile(), {
        enabled: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
    });
}
export const useEditAgentProfile = () => {
    const queryClient = useQueryClient();
    return useMutation((formData) => api.editAgentProfile(formData), {
        onSuccess: () => {
            queryClient.refetchQueries('AgentMyProfiles');
        },
    });
}
export const useChangeAgentContact = () => {
    return useMutation((formData) => api.changeContact(formData));
}
export const useAgentVerifyOtp = () => {
    return useMutation((formData) => api.agentVerifyOtp(formData));
}
export const useAgentResendOtp = () => {
    return useMutation((formData) => api.agentResendOtp(formData));
}
export const useChangeAgentPassword = () => {
    return useMutation((formData) => api.changeAgentPassword(formData));
}
export const useProfileActive = () => {
    const queryClient = useQueryClient();
    return useMutation((formData) => api.profileActive(formData), {
        onSuccess: () => {
            queryClient.refetchQueries('AllProfileView');
        }
    });
}

export const useProfileDeactive = () => {
    const queryClient = useQueryClient();
    return useMutation((formData) => api.profileDeactive(formData), {
        onSuccess: () => {
            queryClient.refetchQueries('AllProfileView');
        }
    });
}

export const useProfileEnterView = () => {
    return useMutation((formData) => api.profileEnterView(formData));
}