import React from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { Row, Col } from 'antd';
import Scroll from 'views/shared-components/scrolldiv/scroll';
import Image from 'helpers/Image'
import { useAgentMyProfiles } from 'hooks/agent/MyProfiles';
import { useMemberOwnProfile } from 'hooks/member/Profiles';
import { AGENT_PREFIX, MEMBER_PREFIX } from 'helpers/configs';
import Cookies from 'js-cookie';
import { useEffect } from 'react';
let role = Cookies.get("sm_role");

const CommonFooter = () => {
	const location = useLocation();
	const history = useHistory();
	const { data, refetch } = useAgentMyProfiles();
	const { data: userDetails, refetch: refetchMember } = useMemberOwnProfile();

	let PREFIX = "";
	if (role === "member") {
		PREFIX = MEMBER_PREFIX;
	}
	else if (role === "agent") {
		PREFIX = AGENT_PREFIX;
	}

	const scrollToDiv = () => {
		if (location?.pathname === "/member/dashboard") {
			document.getElementById('success-stories').scrollIntoView({ behavior: 'smooth', block: "start" });
		} else {
			history.push('/member/dashboard');
			setTimeout(() => document.getElementById('success-stories').scrollIntoView({ behavior: 'smooth', block: "start" }), 300);
		}
	}

	const scrollToBranch = () => {
		if (location?.pathname === "/member/dashboard") {
			document.getElementById('our-branches').scrollIntoView({ behavior: 'smooth', block: "start" });
		} else {
			history.push('/member/dashboard');
			setTimeout(() => document.getElementById('our-branches').scrollIntoView({ behavior: 'smooth', block: "start" }), 300);
		}
	}

	useEffect(() => {
		if (role === "member") {
			refetchMember();
		}
		if (role === "agent") {
			refetch();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	let cartCount = role === "agent" ? data?.data?.cartCount : userDetails?.data?.cartCount;


	return (
		<>
			<footer className="sm-web-footer sub-sm-web-footer">
				<Scroll />
				<Row gutter={{
					xs: 8,
					sm: 16,
					md: 24,
					lg: 32,
				}}>
					<Col className="gutter-row" span={1}>
					</Col>
					<Col className="gutter-row" span={6} md={6} xs={12}>
						<Image name={"logo.png"} className={"foot-logo"} />
						<h5>Follow us on</h5>
						<div className="social-images">
							<Image name={"facebook.png"} className="facebook" />
							<Image name={"twitter.png"} className="twitter" />
							<Image name={"instagram.png"} className="instagram" />
							<Image name={"youtube.png"} className="youtube" />
						</div>
					</Col>
					<Col className="gutter-row" md={5}>
						<h2>Help & Support</h2>
						<ul>
							<li><a href="https://southmatrimony.tawk.help/"
								target="_blank"
								rel="noopener noreferrer">
								Feedback
							</a></li>
							<li><a href="https://southmatrimony.tawk.help/"
								target="_blank"
								rel="noopener noreferrer">
								FAQs
							</a></li>
							<li><Link to={`${PREFIX}/how-to-use-site`}>How to use this site</Link></li>
							<li><Link to={`${PREFIX}/safety-tips`}>Safety Tips</Link></li>
							<li><Link to={`${PREFIX}/contact`}>24/7 Live Help</Link></li>
						</ul>
					</Col>
					<Col className="gutter-row" md={5}>
						<h2>Information</h2>
						<ul>
							<li><Link to={`${PREFIX}/search/profile-search`}>Partner Search</Link></li>
							<li><Link to={`${PREFIX}/terms-and-conditions`}>Terms & Condition</Link></li>
							<li><Link to={`${PREFIX}/privacy-policy`}>Privacy Policy</Link></li>
						</ul>
					</Col>
					<Col className="gutter-row" md={5}>
						<br></br><br></br>
						{role === "member" && <ul>
							<li><button onClick={scrollToDiv}>Success Stories</button></li>
							<li><button onClick={scrollToBranch}>Branches</button></li>
						</ul>}
					</Col>
					<Col md={2} xs={24} className="scroll">
						{/* <button onClick={scrollToDiv} className="ar-g"><Image name={"arp.svg"} className="arp" /></button> */}
					</Col>
				</Row>

				{<Link to={role === "member" ? '/member/cart' : `/agent/cart`}>
					<button className='cart-g' id="cart-g">
						<Image name={"bags.svg"} className={"bag-g"} />
						{cartCount > 0 && <p className='cart-counts'>{cartCount}</p>}
					</button>
				</Link>}

			</footer>
			<Row className="mini-footer">
				<Col className="gutter-row" span={1}></Col>
				<Col span={23} className="txt-left gutter-row">
					<h5>© Copyright 2022 South Matrimony. All rights reserved.</h5>
				</Col>
			</Row>
		</>
	)
}

export default CommonFooter