import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
// import { ADMIN_PREFIX, WEB_PREFIX } from 'helpers/configs';
import { AlignLeftOutlined } from "@ant-design/icons";
import { Drawer, Menu, Row, Col } from 'antd';
import Image from 'helpers/Image';


const MobileNav = (props) => {
    // let history = useHistory();
    const { count, sp_agent } = props;
    const [open, setOpen] = useState(false);

    const showDrawer = () => {
        setOpen(true);
        // document.body.style.overflow = 'hidden';
    };

    const onClose = () => {
        setOpen(false);
        // if (typeof window != 'undefined' && window.document) {
        //     document.body.style.overflow = 'unset';
        // }
    };

    const onLogout = (e) => {
        e.preventDefault()
        onClose();
        Cookies.remove('sm_register_token');
        Cookies.remove('sm_register_domain');
        Cookies.remove('sm_register_phone');
        Cookies.remove('sm_register_otp');
        Cookies.remove('sm_role');
        Cookies.remove('profile_user_id');
        Cookies.remove('specialAgent');
        Cookies.remove("mob");
        sessionStorage.clear();
        window.location.reload("/web/home");
    }


    return (
        <>
            <AlignLeftOutlined onClick={() => showDrawer()} className="drawer-icon" id="top-head" />
            <Drawer
                placement="right" onClose={onClose}
                visible={open}
                open={open}
            >
                <Row>
                    <Col xs={22}>
                        <Image name="sm1.png" className="drawer-small-logo" />
                    </Col>
                    <Col xs={2} onClick={onClose}>
                        <Image name="cross.png" className="cross-icon" />
                    </Col>
                </Row>
                <Menu
                    theme="dark"
                    mode="vertical"
                    className="sm-main-menu app-head"
                >
                    <Menu.Item key="0">
                        <Link to="/agent/dashboard" onClick={onClose}>
                            <span>Dashboard</span>
                        </Link>
                    </Menu.Item>
                    <Menu.SubMenu key="11" title="Profiles" style={{ marginRight: "-1.2rem" }}>
                        <div id='pro'>
                            <Menu.Item key="111"  >
                                <Link to="/agent/profiles/uploaded-profiles" onClick={onClose}>
                                    <span>Uploaded Profiles</span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="112" >
                                <Link to="/agent/profiles/paid-profiles" onClick={onClose}>
                                    <span>Paid Profiles</span>
                                </Link>
                            </Menu.Item>
                        </div>
                    </Menu.SubMenu>
                    <Menu.SubMenu key="22" title="Matches" style={{ marginRight: "-1.2rem" }}>
                        <Menu.Item key="221">
                            <Link to="/agent/matches/my-shortlisted-profiles" onClick={onClose}>
                                <span>My Shortlisted Profiles</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="222">
                            <Link to="/agent/matches/who-viewed-my-profile" onClick={onClose}>
                                <span>Who Viewed My Profiles</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="223">
                            <Link to="/agent/matches/who-downloaded-my-profile" onClick={onClose}>
                                <span>Who Downloaded My Profiles</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="224">
                            <Link to="/agent/matches/who-shortlisted-my-profile" onClick={onClose}>
                                <span>Who Shortlisted My Profiles</span>
                            </Link>
                        </Menu.Item>
                    </Menu.SubMenu>
                    <Menu.SubMenu key="33" title="Search" style={{ marginRight: "-1.2rem" }}>
                        <Menu.Item key="331">
                            <Link to="/agent/search/profile-search" onClick={onClose}>
                                <span>Profile Search</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="332">
                            <Link to="/agent/search/recently-viewed-profiles" onClick={onClose}>
                                <span>Recently Viewed Profiles</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="333">
                            <Link to="/agent/search/chevvai-dosham-profiles" onClick={onClose}>
                                <span>Chevvai Dosham Profiles</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="334">
                            <Link to="/agent/search/physically-challenged-profiles" onClick={onClose}>
                                <span>Physically Challenged Profiles</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="335">
                            <Link to="/agent/search/widower-divorcee-profiles" onClick={onClose}>
                                <span>Widower / Divorcee Profiles</span>
                            </Link>
                        </Menu.Item>
                    </Menu.SubMenu>
                    <Menu.Item key="44" >
                        <Link to="/agent/reports/uploaded-profiles" onClick={onClose}>
                            <span>Reports</span>
                        </Link>
                    </Menu.Item>
                    {sp_agent && sp_agent === 1 && <Menu.Item key="66">
                        <Link to="/agent/special-agent">
                            <span>Special Agent</span>
                        </Link>
                    </Menu.Item>}
                    <Menu.Item key="55">
                        <Link to="/agent/contact/contact-us" onClick={onClose}>
                            <span>Contact Us</span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="1123">
                        <Link to="/agent/notification" onClick={onClose}>
                            <span>Notifications</span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="56">
                        <Link to="/agent/cart" onClick={onClose}>
                            <span>Cart {`(${count})`}</span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="57">
                        <Link to="/agent/account/profile-details" onClick={onClose}>
                            <span>My Profile</span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="59">
                        <Link to="/web/home" onClick={onLogout}>
                            <span>Logout</span>
                        </Link>
                    </Menu.Item>
                </Menu>
                <Image name="sm2.png" className="footer-icon" />
            </Drawer>
        </>
    )
}

export default MobileNav