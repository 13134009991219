import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { WEB_PREFIX } from "helpers/configs";
import Loader from "views/shared-components/loader/Loader";

export const WebViews = () => {
    return (
        <Suspense fallback={<Loader />}>
            <Switch>
                <Route path={`${WEB_PREFIX}/home`} component={lazy(() => import(`./home`))} />
                <Route path={`${WEB_PREFIX}/about-us`} component={lazy(() => import(`./about-us`))} />
                <Route path={`${WEB_PREFIX}/safety-tips`} component={lazy(() => import(`./safty-tips`))} />
                <Route path={`${WEB_PREFIX}/how-to-use-site`} component={lazy(() => import(`./how-to-use-this-site`))} />
                <Route path={`${WEB_PREFIX}/terms-and-conditions`} component={lazy(() => import(`./terms-and-conditions`))} />
                <Route path={`${WEB_PREFIX}/privacy-policy`} component={lazy(() => import(`./privacy-policy`))} />
                <Route path={`${WEB_PREFIX}/mob/terms-and-conditions`} component={lazy(() => import(`./terms-and-conditions`))} />
                <Route path={`${WEB_PREFIX}/mob/privacy-policy`} component={lazy(() => import(`./privacy-policy`))} />
                <Route path={`${WEB_PREFIX}/profiles`} component={lazy(() => import(`./profiles`))} />
                <Route path={`${WEB_PREFIX}/search`} component={lazy(() => import(`./search`))} />
                <Route path={`${WEB_PREFIX}/all-grooms`} component={lazy(() => import(`./see-more/groom`))} />
                <Route path={`${WEB_PREFIX}/all-brides`} component={lazy(() => import(`./see-more/bride`))} />
                <Route path={`${WEB_PREFIX}/view-profile/:id`} component={lazy(() => import(`./profiles/viewprofile`))} />
                <Route path={`${WEB_PREFIX}/user/view-profile/:id`} component={lazy(() => import(`./profiles/viewprofile`))} />
                <Route path={`${WEB_PREFIX}/contact-us`} component={lazy(() => import(`./contact-us`))} />
                <Redirect from={`${WEB_PREFIX}`} to={`${WEB_PREFIX}/home`} />
            </Switch>
        </Suspense>
    )
}

export default WebViews;